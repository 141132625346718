import React from 'react'
import { useSelector } from 'react-redux'
import { ListOfDaysElement } from './ListOfDaysElement'
import { SummaryDaysCard } from './SummaryDaysCard'

export const ListOfDays = () => {
  const { days } = useSelector((state) => state)
  const diasHabiles = days.listOfDays.filter((element) => {
    return element.habil === true
  })
  const diasInhabiles = days.listOfDays.filter((element) => {
    return element.habil === false
  })
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 text-center my-4">
        <SummaryDaysCard
          bg="bg-gray-100"
          label="Días Corridos"
          days={days.listOfDays.length}
        />
        <SummaryDaysCard
          bg="bg-green-100"
          label="Días Habiles"
          days={diasHabiles.length}
        />
        <SummaryDaysCard
          bg="bg-red-100"
          label="Días Inhabiles"
          days={diasInhabiles.length}
        />
      </div>
      <div className="col-span-3">
        {days.listOfDays.map((day) => {
          return <ListOfDaysElement key={day.date} day={day} />
        })}
      </div>
    </div>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

export const ButtonCount = ({ fn, value, label }) => {
  return <button onClick={() => fn(value)} className="btn-primary"> {label} </button>
}

ButtonCount.propTypes = {
  fn: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
}

export const types = {
  addDay: '[Days] Add',
  removeDay: '[Days] Remove',
  changeDay: '[Days] Change',

  setListOfDays: '[ListDays] Set',

  /* Config Reducer */
  configCorrido: '[Config] corridos',
  configHabil: '[Config] habiles',
  configAdministrativo: '[Config] administrativos',

  /* Holidays Reducer */
  getHolidays: '[Holidays] getHolidays',
  setHolidays: '[Holidays] setHolidays'
}

import { types } from '../types/types'

const initialState = JSON.parse(localStorage.getItem('config-days')) || {
  corrido: false,
  habil: false,
  administrativo: false
}

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.configCorrido:
      return {
        corrido: true,
        habil: false,
        administrativo: false
      }

    case types.configHabil:
      return {
        corrido: false,
        habil: true,
        administrativo: false
      }

    case types.configAdministrativo:
      return {
        corrido: false,
        habil: false,
        administrativo: true
      }

    default:
      return state
  }
}

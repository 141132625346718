import { useState } from 'react'

export const useCounter = (initialState = 10) => {
  const [counter, setCounter] = useState(initialState)

  const increment = (i) => {
    setCounter(counter + i)
  }
  const decrement = (i) => {
    counter > i ? setCounter(counter - i) : setCounter(counter)
  }

  const newValue = (i) => {
    setCounter(i)
  }

  const reset = () => {
    setCounter(initialState)
  }

  return {
    counter,
    increment,
    decrement,
    reset,
    newValue
  }
}

import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import { ChangeDays } from './ChangeDays'
import { useDispatch, useSelector } from 'react-redux'
import { useCountDays } from '../hooks/useCountDays'
import Calendar from 'react-calendar'
import dayjs from 'dayjs'
import { getHolidaysFetch } from '../helpers/getHolidays'
import { setHolidays } from '../actions/holidays'
import { ShowCountDays } from './ShowCountDays'
import { setListOfDays } from '../actions/days'

export const Content = () => {
  registerLocale('es', es)

  const [startDate, setStartDate] = useState(new Date())

  const [finalDate, setFinalDate] = useState('')
  const { countDays } = useCountDays()

  const dispatch = useDispatch()

  const { days, config, holidays } = useSelector((state) => state)

  useEffect(() => {
    async function fetchData() {
      const holidays = await getHolidaysFetch()
      const { finalDateResult, listOfDays } = countDays(
        startDate,
        days.days,
        config,
        holidays
      )
      dispatch(setListOfDays(listOfDays))
      setFinalDate(finalDateResult)
      dispatch(
        setHolidays({
          holidays,
          date: dayjs().add(1, 'day').format()
        })
      )
    }
    if (days.days !== 0) {
      if (dayjs(holidays.date) < dayjs()) {
        fetchData()
      } else {
        const { finalDateResult, listOfDays } = countDays(
          startDate,
          days.days,
          config,
          holidays
        )
        dispatch(setListOfDays(listOfDays))
        setFinalDate(finalDateResult)
      }
    }
  }, [days.days, startDate, config, holidays])

  return (
    <div className="mx-auto lg:max-w-10xl mt-3 ">
      <div className="bg-white p-8 rounded-xl grid md:grid-cols-5 ">
        <div className="col-start-2 col-span-3">
          <div className="text-[20px] text-center grid grid-cols-1 md:grid-cols-5">
            <div className="md:col-start-2 md:col-span-3">
              <h1 className="mb-5">Seleccionar fecha:</h1>
              <Calendar
                onChange={(date) => setStartDate(date)}
                value={startDate}
              />
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale="es"
                className="btn-primary text-center"
                calendarClassName="text-center"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd-MM-Y"
                readOnly
              />
            </div>
          </div>
          <hr className="m-4" />
          <div className="text-center">
            <h1 className="text-[20px]">Cantidad de días a contar:</h1>
            <ChangeDays />
          </div>
          <hr className="m-4" />
          <div className="text-center">
            <h1 className="text-[20px]">Fecha Resultante:</h1>
            <h1 className="text-[30px] font-bold text-gray-700 ">
              {finalDate}
            </h1>
          </div>
          <hr className="m-4" />
          <ShowCountDays />
        </div>
      </div>
    </div>
  )
}

import axios from 'axios'

// eslint-disable-next-line space-before-function-paren
export const getHolidaysFetch = async (year) => {
  const url = 'https://api.contardias.cl/api/holidays'
  const resp = await fetch(url, {
    method: 'GET'
  })
  const data = await resp.json()
  const holidays = data.map((data) => {
    return {
      date: data.fecha,
      description: data.nombre
    }
  })

  return holidays
}

// eslint-disable-next-line space-before-function-paren
export const getHolidaysAxios = async (year) => {
  const url = 'https://apis.digital.gob.cl/fl/feriados'
  const res = await axios(url, {
    method: 'GET',
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
  console.log(res)
}

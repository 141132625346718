import React from 'react'
import PropTypes from 'prop-types'

export const SummaryDaysCard = ({ bg, label, days }) => {
  return (
    <>
      <div className="flex justify-center mb-2 mx-2 ">
        <div className={`block rounded-lg shadow-lg w-full ${bg}`}>
          <div className="text-gray-800 text-base m-1 text-center ">
            <div className="rounded-full inline-block font-bold text-[20px]">
              {label}: {days}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

SummaryDaysCard.propTypes = {
  bg: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired
}

import React from 'react'
import { useSelector } from 'react-redux'
import {
  changeAdministrativo,
  changeCorrido,
  changeHabil
} from '../actions/config'
import { ConfigItem } from './ConfigItem'

export const Config = () => {
  const { corrido, habil, administrativo } = useSelector(
    (state) => state.config
  )

  return (
    <div className="mx-auto lg:max-w-10xl sm:max-w-12xl mt-3">
      <div className="bg-white p-8 rounded-xl">
        <h2 className="text-[25px] text-gray-700 font-bold">Tipo de Conteo</h2>
        <h1>Seleccione que tipo de cuenta de días desea hacer.</h1>
        <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
          <ConfigItem
            active={corrido}
            title="Días Corridos"
            typeDispatch={changeCorrido}
            description="Se contarán todos los días, de lunes a domingo, sin descontar feriados de ningún tipo ni nada."
          />
          <ConfigItem
            active={habil}
            title="Días hábiles normales"
            typeDispatch={changeHabil}
            description="Se contarán todos los días hábiles (aquellos que no son feriados de ámbito normal)."
          />
          <ConfigItem
            active={administrativo}
            title="Días hábiles Administrativos."
            typeDispatch={changeAdministrativo}
            description="Se contarán solamente los días lunes a viernes que no sean feriados de ámbito normal."
          />
        </div>
      </div>
    </div>
  )
}

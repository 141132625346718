import React from 'react'
import { Provider } from 'react-redux'
import { DaysScreen } from './screens/DaysScreen'
import { store } from './store/store.js'

export const DaysApp = () => {
  return (
    <Provider store={store}>
      <DaysScreen />
    </Provider>
  )
}

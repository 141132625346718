import React from 'react'
import PropTypes from 'prop-types'

export const ListOfDaysElement = ({ day }) => {
  const { i, habil, date, description, weekday } = day
  return (
    <>
      <div className="flex justify-center mb-2 ">
        <div className={`block rounded-lg shadow-lg w-full ${habil ? 'bg-green-100' : 'bg-red-100'}`}>
          <div className="text-gray-700 text-base m-1 text-center ">
            <div className="rounded-full inline-block font-bold float-left pb-2">#{i}</div>
            <div className='inline-block mx-2 text-[15px] w-[70px] font-bold float-left text-left'>{weekday} </div>
            <div className='inline-block mx-2 text-[20px] font-bold float-right md:float-left'> {date}</div>
            <div className='inline-block mx-5 font-semibold float-right '>{description}</div>
          </div>
        </div>
      </div>
    </>
  )
}

ListOfDaysElement.propTypes = {
  day: PropTypes.object.isRequired
}

import { types } from '../types/types'

export const changeDay = (value) => ({
  type: types.changeDay,
  payload: value
})

export const setListOfDays = (value) => ({
  type: types.setListOfDays,
  payload: value
})

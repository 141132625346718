import { types } from '../types/types'

export const changeCorrido = () => ({
  type: types.configCorrido
})

export const changeHabil = () => ({
  type: types.configHabil
})

export const changeAdministrativo = () => ({
  type: types.configAdministrativo
})

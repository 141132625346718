import dayjs from 'dayjs'
import { types } from '../types/types'
const initialState = JSON.parse(localStorage.getItem('holidays')) || {
  holidays: [],
  date: dayjs().format()
}

export const holidaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getHolidays:
      return {
        corrido: true,
        habil: false,
        administrativo: false
      }

    case types.setHolidays:
      return {
        ...state,
        date: action.payload.date,
        holidays: action.payload.holidays
      }

    default:
      return state
  }
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Config } from '../components/Config'
import { Content } from '../components/Content'
import { Footer, Navbar } from '../layouts'

// eslint-disable-next-line no-unused-vars
const colors = {
  lavenderWeb: '#D9DBF1',
  lavenderGray: '#D0CDD7',
  cardetBlue: '#ACB0BD',
  deepSpark: '#416165',
  greenEagle: '#0B3948'
}

export const DaysScreen = () => {
  const config = useSelector((state) => state.config)
  const holidays = useSelector((state) => state.holidays)
  useEffect(() => {
    localStorage.setItem('config-days', JSON.stringify(config))
  }, [config])

  useEffect(() => {
    localStorage.setItem('holidays', JSON.stringify(holidays))
  }, [holidays])
  return (
    <div className="h-screen">
      <Navbar />
      <div className="container mx-auto px-5 mb-5">
        <Config />
        <Content />
      </div>
      <Footer />
    </div>
  )
}

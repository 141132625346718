import dayjs from 'dayjs'

const initialConfig = {
  corrido: false,
  habil: false,
  administrativo: false
}

export const useCountDays = (startDate, days, config = initialConfig) => {
  const countDays = (startDate, days, config = initialConfig, { holidays }) => {
    const weekdays = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miercoles',
      'Jueves',
      'Viernes',
      'Sabado'
    ]
    const currentJs = dayjs(startDate)
    let finalDate = ''
    const listOfDays = []
    let i = 1

    if (config.corrido) {
      finalDate = currentJs.add(i, 'day')
      do {
        finalDate = currentJs.add(i, 'day')
        console.log(finalDate.format('DD-MM-YYYY'))
        listOfDays.push({
          i: i <= 9 ? '0' + i : i,
          habil: true,
          date: finalDate.format('DD-MM-YYYY'),
          description: 'Día Natural',
          weekday: weekdays[finalDate.format('d')]
        })
        i++
      } while (i <= days)
      return {
        finalDateResult: finalDate.format('DD-MM-YYYY'),
        listOfDays
      }
    }
    finalDate = currentJs.add(i, 'day')
    do {
      finalDate = currentJs.add(i, 'day')
      if (holidays) {
        const actualHoliday = holidays.find(
          (element) => element.date === finalDate.format('YYYY-MM-DD')
        )
        if (actualHoliday) {
          listOfDays.push({
            i: i <= 9 ? '0' + i : i,
            habil: false,
            date: finalDate.format('DD-MM-YYYY'),
            description: actualHoliday.description,
            weekday: weekdays[finalDate.format('d')]
          })
          days++
          i++
          continue
        }
      }

      if (finalDate.format('d') === '0') {
        listOfDays.push({
          i: i <= 9 ? '0' + i : i,
          habil: false,
          date: finalDate.format('DD-MM-YYYY'),
          description: 'Día Domingo',
          weekday: weekdays[finalDate.format('d')]
        })
        days++
        i++
        continue
      }

      if (!config.habil && finalDate.format('d') === '6') {
        listOfDays.push({
          i: i <= 9 ? '0' + i : i,
          habil: false,
          date: finalDate.format('DD-MM-YYYY'),
          description: 'Día Sabado',
          weekday: weekdays[finalDate.format('d')]
        })
        days++
        i++
        continue
      }
      listOfDays.push({
        i: i <= 9 ? '0' + i : i,
        habil: true,
        date: finalDate.format('DD-MM-YYYY'),
        description: 'Día Natural',
        weekday: weekdays[finalDate.format('d')]
      })
      i++
    } while (i <= days)

    return {
      finalDateResult: finalDate.format('DD-MM-YYYY'),
      listOfDays
    }
  }

  return {
    countDays
  }
}

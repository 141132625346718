import React, { useState } from 'react'
import { ListOfDays } from './ListOfDays'

export const ShowCountDays = () => {
  const [showDays, setShowDays] = useState(false)

  const handleShowDays = () => {
    setShowDays(!showDays)
  }

  return (
    <div>
      <div className="text-center">
        <button onClick={() => handleShowDays()} className="btn-primary">
          {showDays ? 'Ocultar' : 'Mostrar'} Listado de Días
        </button>
      </div>
      {showDays && <ListOfDays />}
      {showDays && (
        <div className="text-center">
          <button onClick={() => handleShowDays()} className="btn-primary">
            {showDays ? 'Ocultar' : 'Mostrar'} Listado de Días
          </button>
        </div>
      )}
    </div>
  )
}

import { configureStore } from '@reduxjs/toolkit'
import { configReducer } from '../reducers/configReducer'
import { daysReducer } from '../reducers/daysReducer'
import { holidaysReducer } from '../reducers/holidaysReducer'

export const store = configureStore({
  reducer: {
    days: daysReducer,
    config: configReducer,
    holidays: holidaysReducer
  }
})

import { types } from '../types/types'

const initialState = {
  days: 1,
  listOfDays: []
}

export const daysReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.addDay:
      return {
        ...state,
        days: state.days + action.payload
      }
    case types.removeDay:
      return {
        ...state,
        days: state.days - action.payload
      }

    case types.changeDay:
      return {
        ...state,
        days: action.payload
      }

    case types.setListOfDays:
      return {
        ...state,
        listOfDays: action.payload
      }

    default:
      return state
  }
}

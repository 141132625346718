import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeDay } from '../actions/days'
import { useCounter } from '../hooks/useCounter'
import { useForm } from '../hooks/useForm'
import { ButtonCount } from './ButtonCount'

export const ChangeDays = () => {
  const dispatch = useDispatch()

  const { days: daysSelector } = useSelector((state) => state.days)

  const { counter, increment, decrement, newValue } = useCounter(daysSelector)
  const initialForm = {
    days: counter
  }
  const [values, handleInputChange] = useForm(initialForm)
  const { days } = values

  const handleChangeDays = (e) => {
    handleInputChange(e)
    newValue(parseInt(e.target.value) || 1)
  }

  useEffect(() => {
    handleChangeDays({
      target: {
        name: 'days',
        value: counter
      }
    })
    dispatch(changeDay(counter))
  }, [counter, dispatch])

  return (
    <div className="align-middle">
      <ButtonCount fn={decrement} value={60} label="-60" />
      <ButtonCount fn={decrement} value={30} label="-30" />
      <ButtonCount fn={decrement} value={10} label="-10" />
      <ButtonCount fn={decrement} value={1} label="-1" />
      <div className="md:inline">
        <input
          type="text"
          value={days}
          onChange={handleChangeDays}
          name="days"
          className="text-center w-20 text-[19px] border-blue-500 border-2 rounded"
        />
      </div>
      <ButtonCount fn={increment} value={1} label="+1" />
      <ButtonCount fn={increment} value={10} label="+10" />
      <ButtonCount fn={increment} value={30} label="+30" />
      <ButtonCount fn={increment} value={60} label="+60" />
    </div>
  )
}
